import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./header.css";
import { NavLink } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

const Header = () => {
  return (
    <header>
      <div id="full">
        <div className="signature">                  
          <NavLink key="Home" title="Home" to="/">
          Oscar Rosete
          </NavLink>
        </div>
        <div className="option">
          <NavLink key="Courses" title="Courses" to="/">
          Professor |
          </NavLink>
        </div>
        <div className="option">
          <NavLink key="Software" title="Software" to="/">
          Software Developer |
          </NavLink>
        </div>
        <div className="option">
          <NavLink key="Automation" title="Automation" to="/">
          Automation Specialist |
          </NavLink>
        </div>
      </div>
    
      <div id="alternative">
        <div className="signature">     
          <Accordion>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="777"  className="activityLink">
                Oscar Rosete
              </Accordion.Toggle> 
            </Card.Header>
            <Accordion.Collapse eventKey="777" >
              <Card.Body className="activityLinkDescription">
                <NavLink key="Home" title="Home" to="/">
                  Home
                </NavLink>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          </Accordion>             

        </div>
      </div>
    </header>
  );
};

export default Header;