import React from 'react'
import "./Gallery.css";

const Gallery = (props) => {
  return (
    <div id="imgsContainer">
        {props.imgList.map((elemento, index) => (
          <div className="col-12 col-md-4 imgContainer1a" key={index}>
            <div className="imgContainer1b">
              <img className="galleryImage" src={elemento.url}></img>
              <p className="galleryImage-description">{elemento.description}</p>
              <div className="galleryImage-cover"></div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default Gallery