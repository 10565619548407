// import ButtonAppBar from "./components/navbar/navbar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
// import {
//   faHome,
//   faChevronLeft,
//   faMapMarkerAlt
// }
// from "@fortawesome/free-solid-svg-icons";
import {
  faBook,
  faCalendarCheck,
  faEnvelope,
  faFilm,
} from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/footer";
import Header from "./components/Header/header";
import automation2020Page from "./pages/automation/automation2020";
import circuitosPage from "./pages/circuitosII/circuitos2";
import indexPage from "./pages/default/index";
import electricalcircuits2020Page from "./pages/electricalcircuits2/circuits2020";
import electricalcircuits2021Page from "./pages/electricalcircuits3/circuits2021";
import electricalcircuits2022Page from "./pages/electricalcircuits2022/circuits2022";
import electricalenergy2021Page from "./pages/electricalenergy/electrical2021";
import electropneumatics2020Page from "./pages/electropneumatics/electropneumatics2020";
import electropneumatics2021Page from "./pages/electropneumatics2021/electropneumatics2021";
import electropneumatics2022Page from "./pages/electropneumatics2022/electropneumatics2022";
import energy2020Page from "./pages/energyconversion/energy2020";
import energy2021Page from "./pages/energyconversion2021/energy2021";
import staticsPage from "./pages/estatica/statics";
import statics2020Page from "./pages/estatica/statics2020";
import Intelligent2021Page from "./pages/intelligentsystems/intelligent2021";
import Intelligent2022Page from "./pages/intelligentsystems/intelligent2022";
import networks2022Page from "./pages/computernetworks/networks2022";
import networks2023Page from "./pages/computernetworks/networks2023";
import mechanisms2021Page from "./pages/mechanisms/mechanisms2021";
import mechanisms2022Page from "./pages/mechanisms/mechanisms2022";
import mechanisms2023Page from "./pages/mechanisms/mechanisms2023";
import microPage from "./pages/microcontroladores/micro";
import micro2020Page from "./pages/microcontroladores/micro2020";
import micro2021Page from "./pages/microcontroladores/micro2021";
import micro2022Page from "./pages/microcontroladores/micro2022";
import micro2023Page from "./pages/microcontroladores/micro2023";
import multivariablecalculus2020Page from "./pages/multivariablecalculus/calculus2020";
import neumaticaPage from "./pages/neumatica/neumatica";
import potencia2020Page from "./pages/potencia/potencia2020";
import technical2020Page from "./pages/technical/technical2020";

const NotFoundRedirect = () => <Redirect to="/" />;

class App extends Component {
  render() {
    library.add([
      faFacebook,
      faLinkedin,
      faEnvelope,
      faBook,
      faFilm,
      faCalendarCheck,
      // faHome,
      // faChevronLeft,
      // faMapMarkerAlt
    ]);
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <div className="layout">
          {/* <MainNavigation /> */}
          <Header />
          <main className="main-content">
            <Switch>
              <Route path="/" exact component={indexPage} />
              <Route exact path="/microcontroladores" component={microPage} />
              <Route
                exact
                path="/microcontroladores2020"
                component={micro2020Page}
              />
              <Route exact path="/estatica" component={staticsPage} />
              <Route exact path="/estatica2020" component={statics2020Page} />
              <Route
                exact
                path="/technical2020"
                component={technical2020Page}
              />
              <Route exact path="/circuitos2" component={circuitosPage} />
              <Route exact path="/neumatica" component={neumaticaPage} />
              <Route exact path="/potencia2020" component={potencia2020Page} />
              <Route
                exact
                path="/automation-2020"
                component={automation2020Page}
              />
              <Route
                exact
                path="/electropneumatics-2020"
                component={electropneumatics2020Page}
              />
              <Route
                exact
                path="/electropneumatics-2021"
                component={electropneumatics2021Page}
              />
              <Route
                path="/electropneumatics-2022"
                component={electropneumatics2022Page}
              />
              <Route
                exact
                path="/electrical-circuits-2020"
                component={electricalcircuits2020Page}
              />
              <Route
                exact
                path="/electrical-circuits-2021"
                component={electricalcircuits2021Page}
              />
              <Route
                path="/electrical-circuits-2022"
                component={electricalcircuits2022Page}
              />
              <Route
                exact
                path="/multivariable-calculus-2020"
                component={multivariablecalculus2020Page}
              />
              <Route
                exact
                path="/energy-conversion-2020"
                component={energy2020Page}
              />
              <Route
                exact
                path="/energy-conversion-2021"
                component={energy2021Page}
              />
              <Route
                exact
                path="/microcontrollers-2021"
                component={micro2021Page}
              />
              <Route
                exact
                path="/intelligent-systems-2021"
                component={Intelligent2021Page}
              />
              <Route
                exact
                path="/mechanisms-2021"
                component={mechanisms2021Page}
              />
              <Route
                exact
                path="/electrical-power-systems-2021"
                component={electricalenergy2021Page}
              />
              <Route
                exact
                path="/microcontrollers-2022"
                component={micro2022Page}
              />
              <Route
                exact
                path="/microcontrollers-2023"
                component={micro2023Page}
              />
              <Route
                exact
                path="/intelligent-systems-2022"
                component={Intelligent2022Page}
              />
              <Route
                exact
                path="/mechanisms-2022"
                component={mechanisms2022Page}
              />
              <Route
                exact
                path="/mechanisms-2023"
                component={mechanisms2023Page}
              />
              <Route
                exact
                path="/computer-networks-2022"
                component={networks2022Page}
              />
              <Route
                exact
                path="/computer-networks-2023"
                component={networks2023Page}
              />
              <Route component={NotFoundRedirect} />
            </Switch>
          </main>
          <Footer />
        </div>
        {/* <MainNavigation /> */}
        {/* <Button variant="contained" color="primary">
          Hello World
        </Button> */}
      </BrowserRouter>
    );
  }
}

export default App;
