import React, { Component } from "react";
import { Helmet } from "react-helmet";
import CourseHeader from "../../components/CourseHeader/CourseHeader";
import CurrentActivities from "../../components/CurrentActivities/CurrentActivities";
import CurrentActivities2 from "../../components/CurrentActivities2/CurrentActivities2";
import Gallery from "../../components/Gallery/Gallery";
import SlideGallery from "../../components/SlideGallery/SlideGallery";
import { baseUrl } from "../../utils/constants";
import MyMethods from "../constants.js";
import "./networks.css";
//======setup de la materia==============//
const updateActivities = MyMethods.updateActivities;
const updateSlides = MyMethods.updateSlides;
const updateImages = MyMethods.updateImages;
const updateReadings = MyMethods.updateReadings;
const updateVideos = MyMethods.updateVideos;
const updateHandouts = MyMethods.updateHandouts;
const BannerImg =
  "https://live.staticflickr.com/65535/51777430433_faaf0f61f5_c.jpg";
//======setup de la materia==============//
const InformacionCurso = {
  nombre: "Redes de computadoras",
  ano: "2023-1",
  firstDivider: "Semana Actual",
  secondDivider: "Material de Clase Archivado",
};

const activitiesUrl = "activities-networks-2023-s";
const readingsUrl = "readings-networks-2023-s";
const videosUrl = "videos-networks-2023-s";
const handsoutsUrl = "handouts-networks-2023-s";
const imagesUrl = "gallery-networks-2023-s";
const slidesUrl = "slides-networks-2023-s";
const BannerAuthor = "Kirill Sh on Unsplash";

export class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      activities: [],
      readings: [],
      videos: [],
      handouts: [],
      images: [],
      slides: [],
    };
  }

  async componentDidMount() {
    console.log("pidiendo");
    let arr = await updateActivities(baseUrl, activitiesUrl);
    let arr2 = await updateReadings(baseUrl, readingsUrl);
    let arr3 = await updateVideos(baseUrl, videosUrl);
    let arr4 = await updateHandouts(baseUrl, handsoutsUrl);
    let arr5 = await updateImages(baseUrl, imagesUrl);
    let arr6 = await updateSlides(baseUrl, slidesUrl);

    this.setState({
      activities: arr,
      readings: arr2,
      videos: arr3,
      handouts: arr4,
      images: arr5,
      slides: arr6,
    });
  }

  render() {
    return (
      <div className="home">
        <Helmet>
          <title>Curso {InformacionCurso.nombre}</title>
          <meta
            name="description"
            content={`Curso de ${InformacionCurso.nombre} impartido en Cetys universidad por Oscar Alonso Rosete Beas`}
          />
        </Helmet>

        <CourseHeader
          title={`${InformacionCurso.nombre} ${InformacionCurso.ano}`}
          img={BannerImg}
          img_author={BannerAuthor}
          phrase='"Scientists discover the world that exists; Engineers create the world that never was"'
          author="-Theodore Von Karman"
        />

        <div className="Divider1a">
          <div className="Divider1b">
            <p>{InformacionCurso.firstDivider}</p>
          </div>
        </div>

        <CurrentActivities
          readings={this.state.readings}
          videos={this.state.videos}
          activities={this.state.activities}
        />

        <CurrentActivities2
          activities={this.state.handouts}
          url="https://calendar.google.com/calendar/b/5/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FTijuana&amp;src=dTMxZWdvZDY4NzNnOXU2M3FoZXAyZWk2MDhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23A79B8E&amp;mode=MONTH&amp;showPrint=0&amp;showTabs=0&amp;showNav=0&amp;showDate=0&amp;showTz=0&amp;hl=en&amp;showCalendars=0&amp;showTitle=0"
        />

        <div className="Divider1a">
          <div className="Divider1b">
            <p>{InformacionCurso.secondDivider}</p>
          </div>
        </div>

        <SlideGallery slideArray={this.state.slides} />

        <div className="Divider1a">
          <div className="Divider1b">
            <p>Photo Gallery</p>
          </div>
        </div>

        <Gallery imgList={this.state.images} />
      </div>
    );
  }
}
export default HomePage;
