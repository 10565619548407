import React, { Component } from "react";
// import { Container, Row, Col } from "react-bootstrap";

// import Intro from "../components/Home/Intro/Intro";
// import About from "../components/Home/About/About";
// import Services from "../components/Home/Services/Services";
// import Networks from "../components/Home/Networks/Networks";
// import Contact from "../containers/Contact/Contact";

// ponermetatags conhelmet
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner/Banner";
import "./micro.css";
import BannerImg from "../../assets/images/micro/micro.jpg";

export class HomePage extends Component {


  render() {
    const jsonArray=
    [
      {
        Texto:"Semana 1-1",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vTH8GNT7eEXsrUdzw5jDJdjl5iPxZKIl9CjC7KmbTfqSx5Zfd5F6iaeSxH6rpinmzJZJjmYonLr8kOC/embed?start=false&loop=false&delayms=3000"
      },
      {
      Texto:"Semana 1-2",
      slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vSLF_EuCBCT12PcMt9Z_FTepyMTzGe654YJ2cm-1gmkOi9sIl-b4a36q_phF3N6vQqYMRjSpHtZHekj/embed?start=false&loop=false&delayms=3000"
      },
      {
        Texto:"Semana 1-3",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vQgViBM7SCMw61niHNcgN6Pz5R4wLcoNdu6jrH2QukwYl0enUnU-vcBrcHITTSA9CRJz7eABNZQaDVj/embed?start=false&loop=false&delayms=3000" 
      },
      {
        Texto:"Semana 2-1",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vTouFQm4aju3BC3T_0e42NnLfB0O3i8A3HO0MxA8v6WKml47TxstsI1Mp6Yp_SLQgjWFfFb4ZHjL-2p/embed?start=false&loop=false&delayms=3000"
      },
      {
        Texto:"Semana 2-2",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vTMXpNREBQcVaxOAgGmoUi-lSeLvNt9Uf8hQT4V-_voCC2BycOQGUrAS3ePs4gE_7Pw22RuutuIHli0/embed?start=false&loop=false&delayms=3000" 
      },
      {
        Texto:"Semana 2-3",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vQmiMP9ZtqborCaZhwYWDdOxWdxjL2EbmZVIFATGp-Wbi3VNDYsXxSkSzVtTxytws815bBUHNuu8ZR5/embed?start=false&loop=false&delayms=3000" 
      },
      {
        Texto:"Semana 3-1",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vTGrfneczrTuI-6E-9xeOy4XZoBpjLmKQMcJixT9nRJKxQpHKi5Xqywjs7a1lQsKNYp9tmziMC5dOdF/embed?start=false&loop=false&delayms=3000"
      }
    ]

    return (
      <div className="home">
        <Helmet>
          <title>Curso Microcontroladores</title>
          <meta
            name="description"
            content="Curso de Microcontroladores impartido en Cetys universidad por Oscar Alonso Rosete Beas"
          />
        </Helmet>
        <Banner
          img={BannerImg}
          phrase='"Scientists discover the world that exists; Engineers create the world that never was"'
          author="-Theodore Von Karman"
        />
        <h1 className="page__title">Bienvenido a nuestro curso!</h1>
        <div id="miflex">
            {jsonArray.map((elemento, index) => (
              <div className="col-6" >
              {elemento.Texto}
              <iframe src={elemento.slidesUrl}
              width="100%" height="80%" frameborder="0"  allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default HomePage;