import React, { Component } from "react";
import { Helmet } from "react-helmet";
import CourseHeader from "../../components/CourseHeader/CourseHeader";
import Gallery from "../../components/Gallery/Gallery";
import SlideGallery from "../../components/SlideGallery/SlideGallery";
import CurrentActivities from "../../components/CurrentActivities/CurrentActivities";
import CurrentActivities2 from "../../components/CurrentActivities2/CurrentActivities2";
import MyMethods from "./constants.js"
//======setup de la materia==============//
import BannerImg from "../../assets/images/statics/bridge3.jpg";
import "./statics.css";
//======setup de la materia==============//
const updateActivities = MyMethods.updateActivities;
const updateSlides = MyMethods.updateSlides;
const updateImages = MyMethods.updateImages;
const updateReadings = MyMethods.updateReadings;
const updateVideos = MyMethods.updateVideos;
const updateHandouts = MyMethods.updateHandouts;
//======setup de la materia==============//
const InformacionCurso={
  nombre:"Estática",
  ano:"2020-1"
}
const baseUrl= "http://www.oscarrosete.com/api/"
const activitiesUrl="activities-estatica-2020-s"
const readingsUrl="readings-statics-2020-s"
const videosUrl="videos-statics-2020-s"
const handsoutsUrl="handouts-estatica-2020-s"
const imagesUrl="gallery-statics-2020-s"
const slidesUrl="slides-statics-2020-s"
//======setup de la materia==============//
const jsonArray=
[
  {
    Texto:"Semana 1-1",
    slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vQ2BWntAB3vklPti7Fq1-POV6vi9EMTUiz2Va-ZTuL-TctogJXTGXCnqaLy9URpSAZUbspw3UTt5HGw/embed?start=false&loop=false&delayms=3000"
  },
  {
  Texto:"Semana 1-2",
  slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vTs-KblkRxauV5THXrhqEjKsF8XV85sxDGBh_IRcJ1Li0q4XlPF3lzVBrjqi8PNKasx7f5bZ0VQgVCY/embed?start=false&loop=false&delayms=3000", 
  },
  {
    Texto:"Semana 1-3",
    slidesUrl:"https://slides.com/oscaralonso11/deck-60c19c/embed", 
  },
  {
    Texto:"Semana 2-1",
    slidesUrl:"https://slides.com/oscaralonso11/estatica-s1-3-f1bae9/embed"
  },
  {
    Texto:"Semana 2-2",
    slidesUrl:"https://slides.com/oscaralonso11/estatica-s1-3-3f0559/embed"
  },
  {
    Texto:"Semana 2-3",
    slidesUrl:"https://slides.com/oscaralonso11/estatica-s1-3-bc38fd/embed"
  },
  {
    Texto:"Semana 3-1",
    slidesUrl:"https://slides.com/oscaralonso11/estatica-s3-1/embed"
  },
  {
    Texto:"Semana 3-2",
    slidesUrl:"https://slides.com/oscaralonso11/estatica-s3-2/embed"
  },
  {
    Texto:"Semana 3-3",
    slidesUrl:"https://slides.com/oscaralonso11/estatica-s3-3/embed"
  },
  {
    Texto:"Semana 4-1",
    slidesUrl:"https://slides.com/oscaralonso11/estatica-s4-1/embed"
  },
  {
    Texto:"Semana 4-2",
    slidesUrl:"https://slides.com/oscaralonso11/estatica-s4-2/embed"
  },
  {
    Texto:"Semana 5-1",
    slidesUrl:"https://slides.com/oscaralonso11/estatica-s5-1/embed"
  },
  {
    Texto:"Semana 5-2",
    slidesUrl:"https://slides.com/oscaralonso11/est-s5-2/embed"
  },
  {
    Texto:"Semana 6-1",
    slidesUrl:"https://slides.com/oscaralonso11/est-s6-1/embed"
  },
  {
    Texto:"Semana 6-2",
    slidesUrl:"https://slides.com/oscaralonso11/est-s6-2/embed"
  },
  {
    Texto:"Semana 6-3",
    slidesUrl:"https://slides.com/oscaralonso11/est-s6-3/embed"
  },
  {
    Texto:"Semana 7-1",
    slidesUrl:"https://slides.com/oscaralonso11/est-s7-1/embed"
  },
  {
    Texto:"Semana 7-2",
    slidesUrl:"https://slides.com/oscaralonso11/est-s7-2/embed"
  },
  {
    Texto:"Semana 8-1",
    slidesUrl:"https://slides.com/oscaralonso11/est-s8-1/embed"
  },
  {
    Texto:"Semana 8-2",
    slidesUrl:"https://slides.com/oscaralonso11/est-s8-2/embed"
  },
]

const readingsList=[
  {
    titulo:'Primera Lectura (Bedford&Fowler)',
    descripcion:'Leer Páginas 121 a 125, Mecánica para Ingeniería. Estática (Bedford&Fowler)',
    enlace:'https://elibro.net/es/ereader/cetys/74155?page=141'
  }, 
  {
    titulo:'Segunda Lectura (Beer&Johnston)',
    descripcion:'Leer Páginas 73 a 86, Mecánica vectorial para ingenieros(Beer&Johnston)',
    enlace:'https://elibro.net/es/ereader/cetys/101893?page=99'
  },
  {
    titulo:'Tercera Lectura (Romero)',
    descripcion:'Leer Páginas 64 a 69, Mecánica: estática y cálculo vectorial (Romero)',
    enlace:'https://ebiblio.cetys.mx:4153/lib/cetyssp/reader.action?docID=4849766&ppg=68'
  },
  // {
  //   titulo:'Cuarta Lectura (Hibbeler)',
  //   descripcion:'Leer Páginas 117 a 125, Ingenieria Mecánica Estática 12 edicion (Hibbeler)',
  //   enlace:"https://elibro.net/es/ereader/cetys/85091?page=135"
  // }
]

const videosList=[
  {
    titulo:'Primer Video',
    enlace:'https://youtube.com/embed/im-y5TN6S54'
  },
  {
    titulo:'Segundo Video',
    enlace:'https://youtube.com/embed/mBAR_v_p6w0'
  },
  {
    titulo:'Tercer Video',
    enlace:'  https://youtube.com/embed/J7BqKLS8PD0'
  },
  {
    titulo:'Cuarto Video',
    enlace:'https://youtube.com/embed/z9onfy_K0PM'
  }
]

const activitiesList=[
  {
    titulo:'Primera Actividad',
    descripcion:`Realizar los ejercicios visualizados en la presentación 
    y subir en la actividad semana 1 de blackboard`,
    deadline:'27/03/20',
    presentation:'https://slides.com/oscarrosete/tarea-semana1/embed',
  },
  {
    titulo:'Segunda Actividad',
    descripcion:`Realizar los ejercicios visualizados en la presentación 
    y subir en la actividad semana 2 de blackboard`,
    deadline:'03/04/20',
    presentation:'https://slides.com/oscarrosete/tarea-semana2/embed',
  },
  // {
  //   titulo:'Segunda Actividad',
  //   descripcion:'Leer Páginas 80 a 89',
  //   deadline:'27/03/20',
  //   presentation:'https://slides.com/oscarrosete/tarea-semana1/embed',
  //   enlace:'https://ebiblio.cetys.mx:4153/lib/cetyssp/reader.action?docID=4849766&ppg=83'
  // }
]

const handOutsList=[
  {
    titulo:'Presentación semana 23-29 Marzo ',
    descripcion:`Momentos 2D Introducción`,
    presentation:'https://slides.com/oscarrosete/est-s10/embed',
  },
  {
    titulo:'Presentación semana 30-Marzo-5 Abril ',
    descripcion:`Momentos 2D y 3D`,
    presentation:'https://slides.com/oscarrosete/est-s11/embed',
  }
]



export class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      activities:[],
      readings:[],
      videos:[],
      handouts:[],
      images:[],
      slides:[]
    };
  }

  async componentDidMount() {
    console.log("pidiendo")
    let arr= await updateActivities(baseUrl,activitiesUrl);
    let arr2= await updateReadings(baseUrl,readingsUrl);
    let arr3= await updateVideos(baseUrl,videosUrl);
    let arr4=await updateHandouts(baseUrl,handsoutsUrl);
    let arr5=await updateImages(baseUrl,imagesUrl);
    let arr6=await updateSlides(baseUrl,slidesUrl);

    this.setState({
      activities:arr,
      readings:arr2,
      videos:arr3,
      handouts:arr4,
      images:arr5,
      slides:arr6
    })
  }

  render() {
    return (
      <div className="home">
        
        <Helmet>
          <title>Curso {InformacionCurso.nombre}</title>
          <meta
            name="description"
            content={`Curso de ${InformacionCurso.nombre} impartido en Cetys universidad por Oscar Alonso Rosete Beas`}
          />
        </Helmet>
    
        <CourseHeader
          title={`${InformacionCurso.nombre} ${InformacionCurso.ano}`}
          img={BannerImg}
          img_author="Denys Nevozhai"
          phrase='"Scientists discover the world that exists; Engineers create the world that never was"'
          author="-Theodore Von Karman"
        />

        <div className='Divider1a'>
            <div className='Divider1b'>
              <p>Semana Actual (Marzo 23-29)</p>
            </div>
        </div>
        
        <CurrentActivities
          readings={this.state.readings}
          videos={this.state.videos}
          activities={this.state.activities}
        />

        <CurrentActivities2
          activities={this.state.handouts}
          url= "https://calendar.google.com/calendar/b/5/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FTijuana&amp;src=b2ptNHQ2dDBqMWsxMWI5YThtbzBwZXFqa2tAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23A79B8E&amp;mode=MONTH&amp;showPrint=0&amp;showTabs=0&amp;showNav=0&amp;showDate=0&amp;showTz=0&amp;hl=en&amp;showCalendars=0&amp;showTitle=0"
        />
         
        <div className='Divider1a'>
            <div className='Divider1b'>
              <p>Material Pasado</p>
            </div>
        </div>

        <SlideGallery
          slideArray={this.state.slides}
        />

        <div className='Divider1a'>
          <div className='Divider1b'>
            <p>Galería</p>
          </div>
        </div>

        <Gallery
            imgList={this.state.images}
        />
        
      </div>
    );
  }
}
export default HomePage;