import React, { Component } from "react";
import { Helmet } from "react-helmet";
import CourseHeader from "../../components/CourseHeader/CourseHeader";
import Gallery from "../../components/Gallery/Gallery";
import SlideGallery from "../../components/SlideGallery/SlideGallery";
import CurrentActivities from "../../components/CurrentActivities/CurrentActivities";
import CurrentActivities2 from "../../components/CurrentActivities2/CurrentActivities2";
import "./statics.css";
import BannerImg from "../../assets/images/statics/bridge3.jpg";



const readingsList=[
  {
    titulo:'Primera Lectura (Bedford&Fowler)',
    descripcion:'Leer Páginas 121 a 125, Mecánica para Ingeniería. Estática (Bedford&Fowler)',
    enlace:'https://elibro.net/es/ereader/cetys/74155?page=141'
  }, 
  {
    titulo:'Segunda Lectura (Beer&Johnston)',
    descripcion:'Leer Páginas 73 a 86, Mecánica vectorial para ingenieros(Beer&Johnston)',
    enlace:'https://elibro.net/es/ereader/cetys/101893?page=99'
  },
  {
    titulo:'Tercera Lectura (Romero)',
    descripcion:'Leer Páginas 64 a 69, Mecánica: estática y cálculo vectorial (Romero)',
    enlace:'https://ebiblio.cetys.mx:4153/lib/cetyssp/reader.action?docID=4849766&ppg=68'
  },
  {
    titulo:'Cuarta Lectura (Hibbeler)',
    descripcion:'Leer Páginas 117 a 125, Ingenieria Mecánica Estática 12 edicion (Hibbeler)',
    enlace:"https://elibro.net/es/ereader/cetys/85091?page=135"
  }
]

const videosList=[
  {
    titulo:'Primer Video',
    enlace:'https://youtube.com/embed/im-y5TN6S54'
  },
  {
    titulo:'Segundo Video',
    enlace:'https://youtube.com/embed/mBAR_v_p6w0'
  },
  {
    titulo:'Tercer Video',
    enlace:'  https://youtube.com/embed/J7BqKLS8PD0'
  },
  {
    titulo:'Cuarto Video',
    enlace:'https://youtube.com/embed/z9onfy_K0PM'
  }
]

const activitiesList=[
  {
    titulo:'Primera Actividad',
    descripcion:`Realizar los ejercicios visualizados en la presentación 
    y subir en la actividad semana 1 de blackboard`,
    deadline:'27/03/20',
    presentation:'https://slides.com/oscarrosete/tarea-semana1/embed',
  },
  {
    titulo:'Segunda Actividad',
    descripcion:'Leer Páginas 80 a 89',
    deadline:'27/03/20',
    presentation:'https://slides.com/oscarrosete/tarea-semana1/embed',
    enlace:'https://ebiblio.cetys.mx:4153/lib/cetyssp/reader.action?docID=4849766&ppg=83'
  }
]

const imgList=[
  {
    url:`https://via.placeholder.com/800x800/F9F9F9/?text=Foto 1`,
    description:`Descripcion foto 1`
  },
  {
    url:`https://via.placeholder.com/800x800/F9F9F9/?text=Foto 2`,
    description:`Descripcion foto 2`
  },
  {
    url:`https://via.placeholder.com/800x800/F9F9F9/?text=Foto 3`,
    description:`Descripcion foto 3`
  }
]

const handOutsList=[
  {
    titulo:'Primera Presentación',
    descripcion:`Momentos 2D Introducción`,
    presentation:'https://slides.com/oscarrosete/est-s9-1/embed',
  },
  {
    titulo:'Segunda Presentación',
    descripcion:`Momentos 2D continuación`,
    presentation:'https://slides.com/oscarrosete/est-s9-2/embed',
  }
]

const jsonArray=
[
  {
    Texto:"Semana 1-1",
    slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vTT58SWZkTAOJQs1O1rsBPmMrbeNET_FdE4fMz5aJMasBMPe9r-cUk4UhLeZXonhKeOiqgyjRYUm4jx/embed?start=false&loop=false&delayms=3000"
  },
  {
  Texto:"Semana 1-2",
  slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vSsLfZ7OydrhmTljQsq2fWT8BySLlxGQ_aYlMrZEpElFnFId6DX6OLCyGQVyH9Z5vkCBSde8ybfo-G7/embed?start=false&loop=false&delayms=3000", 
  },
  {
    Texto:"Semana 1-3",
    slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vQL-uj6zY7yCHNDNZj6n_M00Q9hoB1wpPCieUAAyMLh-hPjsGUV4KYxg-qymF50DjKmHOLL4yWlCQXH/embed?start=false&loop=false&delayms=3000", 
  },
  {
    Texto:"Semana 2-1",
    slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vT99yubBjtHcox48yce22fEr03RW-zhaRUMhF7jwWDoooosV5j4lpYkWYAzDrCL2D0x6Kf1pdb9zYyJ/embed?start=false&loop=false&delayms=3000"
  },{
    Texto:"Semana 2-2",
    slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vRyAYWtpU_1yxXiNUP5paGXJtlsuhcFtiHgHYkEHQZplbEziu9pdRPNYLqVDOo38n_JIdjkIJ1Yx-yp/embed?start=false&loop=false&delayms=3000" 
  },
  {
    Texto:"Unidad 2",
    slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vTuT5mKbEjfdwHpxl1wGUpFpxrJeIJVh4jGoYufjFzk8GG0BEQ1KAnOO2ssZ1sYfc05wsEqTZkV_giO/embed?start=false&loop=false&delayms=3000"    
  },
  {
    Texto:"Unidad 2 Momentos y coples",
    slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vSms5NZu-GUtKz51j8wjGDhfjFCegueyPTW8O3waGsNwSNeD_jHJdJtNhicONS9N8cbQgfgkxDQq0LB/embed?start=false&loop=false&delayms=3000"     
  },
  {
    Texto:"Unidad 2 Momentos 3D",
    slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vSNF4aqOrDq8a4yblEuTbZVr-8DEVNmKZD-aG_H-lsysZG_R-9hXRp__Czq8lpFowy0L1IOvlESwoQc/embed?start=false&loop=false&delayms=3000"     
    },
    {
      Texto:"Unidad 2 Fuerza-Par",
      slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vSldNABF3wzs59-9OhoB0TvxFlZ7OdhGA3dQH7D1k6fX5a91fXID5TfoKHkTL9bZ05LKRb0vBzcu2wG/embed?start=false&loop=false&delayms=3000"
    },
    {
      Texto:"Unidad 3 Reacciones-2D",
      slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vQ-pcKEmDqKnBQ-G8L8M2Q-zVyDnybTp6Fr6iWwpyTcMrnlX_QufE6ctBkt1OTRf0ZMREAX0SdHOrHQ/embed?start=false&loop=false&delayms=3000"
    },
    {
      Texto:"Unidad 3 Reacciones-3D",
      slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vRlSPUT_4n-M9hPB-ox6viqfmwNsCo_WQxiven48-WDQ2PeRbkdksRrECpihQ_e0SvBITA6g64mhPvI/embed?start=false&loop=false&delayms=3000"
    }
]


export class HomePage extends Component {

  componentDidMount() {
    fetch("https://reqres.in/api/users/2")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
      }
    )
  }
  render() {
    return (
      <div className="home">
        
        <Helmet>
          <title>Curso Estatica</title>
          <meta
            name="description"
            content="Curso de Estatica impartido en Cetys universidad por Oscar Alonso Rosete Beas"
          />
        </Helmet>
    
        <CourseHeader
          title="Estática 2019-1"
          img={BannerImg}
          img_author="Denys Nevozhai"
          phrase='"Scientists discover the world that exists; Engineers create the world that never was"'
          author="-Theodore Von Karman"
        />

        <div className='Divider1a'>
            <div className='Divider1b'>
              <p>Semana Actual</p>
            </div>
        </div>
        
        <CurrentActivities
          readings={readingsList}
          videos={videosList}
          activities={activitiesList}
        />

        <CurrentActivities2
          activities={handOutsList}
          url= "https://calendar.google.com/calendar/b/5/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FTijuana&amp;src=b2ptNHQ2dDBqMWsxMWI5YThtbzBwZXFqa2tAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23A79B8E&amp;mode=MONTH&amp;showPrint=0&amp;showTabs=0&amp;showNav=0&amp;showDate=0&amp;showTz=0&amp;hl=en&amp;showCalendars=0&amp;showTitle=0"
        />
         

        <div className='Divider1a'>
            <div className='Divider1b'>
              <p>Material Pasado</p>
            </div>
        </div>

        <SlideGallery
          slideArray={jsonArray}
        />

        <div className='Divider1a'>
          <div className='Divider1b'>
            <p>Galería</p>
          </div>
        </div>

        <Gallery
            imgList={imgList}
        />
        
      </div>
    );
  }
}

export default HomePage;