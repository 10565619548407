import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <div id="center">Copyright ©2022. All rights reserved</div>
      <div id="developed">Developed by <a target="_blank" rel="noopener noreferrer" href="http://oscarrosete.com">OR | Designed by CG</a></div>
    </footer>
  );
};

export default Footer;