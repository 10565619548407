import React, { Component } from "react";
import { Helmet } from "react-helmet";
import CourseHeader from "../../components/CourseHeader/CourseHeader";
import CurrentActivities from "../../components/CurrentActivities/CurrentActivities";
import CurrentActivities2 from "../../components/CurrentActivities2/CurrentActivities2";
import Gallery from "../../components/Gallery/Gallery";
import SlideGallery from "../../components/SlideGallery/SlideGallery";
import { baseUrl } from "../../utils/constants";
// import BannerImg from "../../assets/images/technical/drawing.jpg";\
import MyMethods from "../constants.js";
import "./automation.css";
//======setup de la materia==============//
const updateActivities = MyMethods.updateActivities;
const updateSlides = MyMethods.updateSlides;
const updateImages = MyMethods.updateImages;
const updateReadings = MyMethods.updateReadings;
const updateVideos = MyMethods.updateVideos;
const updateHandouts = MyMethods.updateHandouts;
const BannerImg="https://live.staticflickr.com/65535/50204769182_82368fd79c_o.jpg";
//======setup de la materia==============//
const InformacionCurso={
  nombre:"Tecnologías de Automatización y Control",
  ano:"2020-2",
  firstDivider:"Semana Actual",
  secondDivider:"Material de Clase Archivado"
}
const activitiesUrl="activities-automation-2020-s"
const readingsUrl="readings-automation-2020-s"
const videosUrl="videos-automation-2020-s"
const handsoutsUrl="handouts-automation-2020-s"
const imagesUrl="gallery-automation-2020-s"
const slidesUrl="slides-automation-2020-s"
const BannerAuthor="Lenny Kuhne"
const jsonArray=
[
  {
    Texto:"Week 1-1",
    slidesUrl:"https://slides.com/oscaralonso11/deck-745992/embed",
  },
  // {
  // Texto:"Week 1-2",
  // slidesUrl:"https://slides.com/oscaralonso11/general-presentation-c2756c/embed"
  // },
  // {
  //   Texto:"Week 1-3",
  //   slidesUrl:"https://slides.com/oscaralonso11/general-presentation-48d67c/embed", 
  // },
  // {
  //   Texto:"Week 2-1",
  //   slidesUrl:"https://slides.com/oscaralonso11/technical-drawing-w1-2/embed",
  // },
  // {
  //   Texto:"Week 2-2",
  //   slidesUrl:"https://slides.com/oscaralonso11/technical-drawing-w1-3/embed"
  // },
  // {
  //   Texto:"Week 3-1",
  //   slidesUrl:"https://slides.com/oscaralonso11/td-w3-1/embed"
  // },
  // {
  //   Texto:"Week 4-1",
  //   slidesUrl:"https://slides.com/oscaralonso11/td-w4-1/embed"
  // },
  // {
  //   Texto:"Week 4-2",
  //   slidesUrl:"https://slides.com/oscaralonso11/td-w4-2/embed"
  // },
  // {
  //   Texto:"Week 5-2",
  //   slidesUrl:"https://slides.com/oscaralonso11/td-w5-2/embed"
  // },
  // {
  //   Texto:"Week 6-1",
  //   slidesUrl:"https://slides.com/oscaralonso11/td-w6-1/embed"
  // },
  // {
  //   Texto:"Week 7-1",
  //   slidesUrl:"https://slides.com/oscaralonso11/td-w7-1/embed"
  // },
  // {
  //   Texto:"Week 7-2",
  //   slidesUrl:"https://slides.com/oscaralonso11/td-w7-2/embed"
  // },
  // {
  //   Texto:"Week 8-1",
  //   slidesUrl:"https://slides.com/oscaralonso11/td-w8-1/embed"
  // },
  // {
  //   Texto:"Week 8-2",
  //   slidesUrl:"https://slides.com/oscaralonso11/td-w8-2/embed"
  // },
  // {
  //   Texto:"Week 9-2",
  //   slidesUrl:"https://slides.com/oscaralonso11/td-w9-2/embed"
  // },
]
const Galeria3=`https://via.placeholder.com/1000x600/F9F9F9/?text=Photo 3`;

  // roscas
  // https://ebiblio.cetys.mx:4153/lib/cetyssp/reader.action?docID=3195858&ppg=18
  // isometric view
  // https://www.youtube.com/watch?v=Vo9LC9d7FQA&list=PLIhUrsYr8yHxVky7bfrnbRcdXcHjT_K83

  // {
  //   titulo:'Second Presentation',
  //   descripcion:`Momentos 2D continuación`,
  //   presentation:'https://slides.com/oscarrosete/est-s9-2/embed',
  // }

export class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      activities:[],
      readings:[],
      videos:[],
      handouts:[],
      images:[],
      slides:[]
    };
  }

  async componentDidMount() {
    console.log("pidiendo")
    let arr= await updateActivities(baseUrl,activitiesUrl);
    let arr2= await updateReadings(baseUrl,readingsUrl);
    let arr3= await updateVideos(baseUrl,videosUrl);
    let arr4=await updateHandouts(baseUrl,handsoutsUrl);
    let arr5=await updateImages(baseUrl,imagesUrl);
    let arr6=await updateSlides(baseUrl,slidesUrl);

    this.setState({
      activities:arr,
      readings:arr2,
      videos:arr3,
      handouts:arr4,
      images:arr5,
      slides:arr6
    })
  }

  render() {
    return (
      <div className="home">
         
         <Helmet>
          <title>Curso {InformacionCurso.nombre}</title>
          <meta
            name="description"
            content={`Curso de ${InformacionCurso.nombre} impartido en Cetys universidad por Oscar Alonso Rosete Beas`}
          />
        </Helmet>
    
        <CourseHeader
          title={`${InformacionCurso.nombre} ${InformacionCurso.ano}`}
          img={BannerImg}
          img_author={BannerAuthor}
          phrase='"Scientists discover the world that exists; Engineers create the world that never was"'
          author="-Theodore Von Karman"
        />

        <div className='Divider1a'>
            <div className='Divider1b'>
              <p>{InformacionCurso.firstDivider}</p>
            </div>
        </div>
        
        <CurrentActivities
          readings={this.state.readings}
          videos={this.state.videos}
          activities={this.state.activities}
        />

        <CurrentActivities2
          activities={this.state.handouts}
          url= "https://calendar.google.com/calendar/b/5/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FTijuana&amp;src=dTMxZWdvZDY4NzNnOXU2M3FoZXAyZWk2MDhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23A79B8E&amp;mode=MONTH&amp;showPrint=0&amp;showTabs=0&amp;showNav=0&amp;showDate=0&amp;showTz=0&amp;hl=en&amp;showCalendars=0&amp;showTitle=0"
        />
         

        <div className='Divider1a'>
            <div className='Divider1b'>
              <p>{InformacionCurso.secondDivider}</p>
            </div>
        </div>

        <SlideGallery
          slideArray={this.state.slides}
        />

        <div className='Divider1a'>
          <div className='Divider1b'>
            <p>Photo Gallery</p>
          </div>
        </div>

        <Gallery
            imgList={this.state.images}
        />
        
      </div>
    );
  }
}
export default HomePage;