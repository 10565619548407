import React from 'react'
import "./SlideGallery.css";

const SlideGallery = (props) => {
  return (
            <div id="miflex">
                {props.slideArray.map((elemento, index) => (
                <div className="col-12 col-md-4 slideContainer" key={index}>
                {elemento.Texto}
                <iframe src={elemento.slidesUrl}
                width="100%" height="80%" frameBorder="2px"  allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                </div>
                ))}
            </div>
        )
}

export default SlideGallery