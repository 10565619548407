import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import "./VideoItem.css";

const VideoItem = (props) => {
  return (
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey={props.index} className="activityLink">
                        {props.titulo}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={props.index}>
                    <Card.Body className="activityLinkDescription">
                        <iframe className="video" src={props.enlace}>
                        </iframe>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            )
        }

export default VideoItem