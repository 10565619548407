import React from 'react';
import Swiper from 'react-id-swiper';
// Version <= 2.3.2
// import 'react-id-swiper/lib/styles/css/swiper.css';
// Version >= 2.4.0
import 'swiper/css/swiper.css';
// https://github.com/kidjp85/react-id-swiper/issues/271
import Typist from 'react-typist';
import Banner1 from "../../../assets/images/caroussel/slide1.jpg";
import Banner2 from "../../../assets/images/caroussel/slide2.jpg";
import "./caroussel.css";



const Caroussel = () => {
    const params = {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        spaceBetween: 30
      }
    return  (
        <Swiper  {...params}>
            <div className="slides">
              <div className="overlay">   
                </div>
              <div className="slides-container">
                <img src={Banner1} alt=""/>
              </div>
              <div className="titleMessage">
                <div className="heading">
                    <p className="main">OSCAR ROSETE</p>
                    <p className="sub typed">
                      <Typist>
                        Software Developer.
                      </Typist>
                    </p>
                </div>
              </div>
            </div>

            <div className="slides">
              <div className="overlay">   
              </div>
              <div className="slides-container">
                <img src={Banner2} alt=""/>
              </div>
              <div className="titleMessage">
                <div className="heading">
                    <p className="main">OSCAR ROSETE</p>
                    <p className="sub typed">
                      <Typist>
                        Automation Specialist.
                      </Typist>
                    </p>
                </div>
            </div>
            </div>
        </Swiper>
    )

}
 
export default Caroussel;