import React, { Component } from "react";
// import { Container, Row, Col } from "react-bootstrap";

// import Intro from "../components/Home/Intro/Intro";
// import About from "../components/Home/About/About";
// import Services from "../components/Home/Services/Services";
// import Networks from "../components/Home/Networks/Networks";
// import Contact from "../containers/Contact/Contact";

// ponermetatags conhelmet
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner/Banner";
import "./circuitos2.css";
import BannerImg from "../../assets/images/circuits/circuit.jpg";

export class circuitosPage extends Component {


  render() {
    const jsonArray=
    [
      {
        Texto:"Semana 1-1",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vSF416wwiqXCVwKx2hDpDntN-TO1wzi1mW_UjuiLN_KEOhwNho1pkGDveGlkFHbnJ7kmV7gbZSXMq0h/embed?start=false&loop=false&delayms=3000" 
      },
      {
      Texto:"Semana 1-2",
      slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vQgblp1fIG-YjJcq1fQI-KVaQLuuY6_yBplOZO6n2VQcVAb0XBkwdmzzjEGOPz8C_crzkk9q3G59lmN/embed?start=false&loop=false&delayms=3000"
      },
      {
        Texto:"Semana 1-3",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vQhbkAdm4OrmGFoLOO1AbAx31g25LnsYoSCu5Vz6FC5ZCNJuMY1bcd9yOOidRk9xiwu4ip3gNsVMwxx/embed?start=false&loop=false&delayms=3000"
      },
      {
        Texto:"Semana 2-1",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vRsff0cec2S7eGGFZ1AdD8ylplluaqCm99J9wdfXprk8rcKB64unOpbfON-4wmMT-0QE7zixzqDtpVr/embed?start=false&loop=false&delayms=3000"
      },
      {
        Texto:"Semana 2-2",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vTMxXMoqUE2In0LDQXoAPl9mxS3wNKscCQQZXzmYiqjKw6qBmLPVtXafCaZFrgLuShoOnUYBBY7InkF/embed?start=false&loop=false&delayms=3000"
      },
      {
        Texto:"Semana 2-3",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vQfixO3Sx0F6y6dYuWSDWlPmOMdgsp_-i6eIc2jpNBB3Fr-zsMY7x3xd0qe6GsAzTFXwi1EyXVDRyw6/embed?start=false&loop=false&delayms=3000"
      },
      //
      {
        Texto:"Semana 3-1",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vQtJHHHFIzq6wD4-dkOaMp4pCuEa2Nf_zMNmOWKXEO2WRakN91TzMBbwbOWTk_XJhtXiYI46IZF3Loh/embed?start=false&loop=false&delayms=3000"
      },
      {
        Texto:"Semana 3-2",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vS8maDfNNb0fYBzFEfR_NVq2Hu1gSrFI1Nhl0dXG87qYLfSDWXaAjjLYMq5cnKP0dc_eGgizX7rM1UC/embed?start=false&loop=false&delayms=3000"
      },
      {
        Texto:"Semana 3-3",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vSyiHL5t9zWXi-KxAAQtsIDE13Gk8X2Xgy50MPnNYcujoOw9zqZcnYu62dWkIjilYSWAALJ3xysoqyx/embed?start=false&loop=false&delayms=3000"
      },
      //
      {
        Texto:"Semana 4-1",
        slidesUrl:`
        https://docs.google.com/presentation/d/e/2PACX-1vSn8ducwWfL-bYnvSaNR7o-dtw2C4hhsZ4DMkFpE0H-4mzA75h6FWtTgZZH5-ldpzGETvQyLsVUwVXd/embed?start=false&loop=false&delayms=3000        `
      },
      {
        Texto:"Semana 4-2",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vTqjqorgurTZsZpICESbYb28mGd11G89Cc0Tmao5BL1mKS74FrX2AiORtYxOXhQArScf2jf53AQm6bB/embed?start=false&loop=false&delayms=3000        
        `
      },
      {
        Texto:"Semana 4-3",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vS_aFSnBppJwoqdLaAUDz6aI9XfnPf0OZ8HZpld3cLNCWXWbKBR8jixSzzYo6YtC9BjQe7s4tp76AhZ/embed?start=false&loop=false&delayms=3000
        `
      },
      //
      {
        Texto:"Semana 5-1",
        slidesUrl:`
        https://docs.google.com/presentation/d/e/2PACX-1vQgP48xktj8YvNyGKi4HLFYUKcaRVBy8tTERueUnRYQQyxQao-fxSjL8lQGUmNuY3wtBUPV0urndq9p/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 5-2",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vRLhvKTchVSAuXPLpDUvZfa_ys6IIvy-EIzuQNBC7BgKzt6JGpRPX0v_TY3d0DaOCb8gouQebxmZiDt/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 5-3",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vQ3bsF0niSwmA4ACsm-QoHfdoXHDEz2E2ZiRCesTk7xR2dvi3WA4CyIlXC-DxpNWIrpT-yrdhE5C4HR/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 6-3",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vQCililINOELDLlXPx4Iyfi1gXgqEVkoPo3Mz0mn7Tpp7uhkpkI78iQNr1ENZet-_TOzbW5Zk4qAqi6/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 7-2",
        slidesUrl: 
        `
        https://docs.google.com/presentation/d/e/2PACX-1vQwIlqvqERmyfqNyYT2VJnVa-mpAoP3AAfej4idhpeV0sPP8pAoY9dFG9hHixZs3pf5Tnk6SVFUaiZ5/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 8-1",
        slidesUrl:
        `https://docs.google.com/presentation/d/e/2PACX-1vTzfdEZJ-szbzfuFcmiTCGWMuOVllWmGR5tmbHffJ-l4U_ZU0wu3AbRmNFICzjSmHly7rBV8_YZOwuq/embed?start=false&loop=false&delayms=3000`
      },
      {
        Texto:"Semana 8-2",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vTJ9-saK0wKKCx6sBJ6Cghd2AOa59B5DgwctFN2APHq_WG8XKbZQD6obmDh8WENZ2UggozTFJOHTEwj/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 8-3",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vTr7Zal278CnXSqaB68V36iDGOLbPiajB8iL1AutWjucVPxZZ-hKPuiGADCrvKueh5XMOSpE5LIWpQw/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 9-1",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vTjSWHLhWsOI3W5n68VSWPsyY6gNVJkjFkHDrR5wRmVceqre9AVLmD9wE5Ew2y1qNwGzO9QZCX1sE1H/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 9-2",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vTJXf2tuRsMuqbYhhH8wxiWDi3Al3NR0CmL5Q-riz5HZGRjVQNRfWQ4yGxkB3nPwgnvHa1U0swOZnZN/embed?start=false&loop=false&delayms=3000 
        `
      },
      {
        Texto:"Semana 9-3",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vQj1NvGhX_AxvsKL3rF1pQaMY0SqQKr8_xV6zM2ggNawgbq3rEkYoY5CujTzaT6jyxlC0tlTL0ABO_x/embed?start=false&loop=false&delayms=3000       
        `
      },
      {
        Texto:"Semana 13-1",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vR5xOrlrmqHKUnlMjcM-eMrBSOWR40GfeusT0lCaBZiVrFGwRc_c3CZSI1yVnHXKIOgz79-hccpS5By/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 13-2",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vSFZzH0v6DZA1fzmTag216XNbr7V5bE4sQXMg5CdBq69KcIRUMze7cmvAb3YhpA42Ugd4iftBZaE8Tn/embed?start=false&loop=false&delayms=3000
        `
      },
      //13-3 practica
      {
        Texto:"Semana 14-1",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vQcwYwhIzRSaX7DDvV6F9HPc-J7msMvVCD1JmcnnZ7pdTg4IseUdSJ27nY6XS77xcKXwAGP2bDUGflh/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 14-2",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vQg3UHp1ZGrQl9JYCF2-dvcumXxjLngapvKGdVPFkFe0XLWOMc_8SVcDjpWSnDWZCaQomXzypXPhzi1/embed?start=false&loop=false&delayms=3000
        `
      }
      //14-3 practica
    ]

    return (
      <div className="home">
        <Helmet>
          <title>Curso Circuitos Eléctricos II</title>
          <meta
            name="description"
            content="Curso de Circuitos Eléctricos II impartido en Cetys universidad por Oscar Alonso Rosete Beas"
          />
        </Helmet>
        <Banner
          img={BannerImg}
          phrase='"Scientists discover the world that exists; Engineers create the world that never was"'
          author="-Theodore Von Karman"
        />
        <h1 className="page__title">Bienvenido a nuestro curso!</h1>
        <div id="miflex">
            {jsonArray.map((elemento, index) => (
              <div className="col-6" >
              {elemento.Texto}
              <iframe src={elemento.slidesUrl}
              width="100%" height="80%" frameBorder="0"  allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default circuitosPage;