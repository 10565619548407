import React from 'react'


import "./Calendar.css";

const Calendar = (props) => {
  return (
            <iframe id="calendarItem" src={props.url}
            frameBorder="0" 
            scrolling="no">
            </iframe>
       )
}

export default Calendar