import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from 'react-bootstrap/Accordion'
import ReadingItem from "./ReadingItem/ReadingItem";
import VideoItem from "./VideoItem/VideoItem";
import ActivityItem from "./ActivityItem/ActivityItem";

import "./CurrentActivities.css";

const CurrentActivities = (props) => {
  return (
            <div id="ActivitiesContainer">
                <div id="readingsContainer">
                    <p>                           
                        <FontAwesomeIcon icon={["fas", "book"]} size="1x" style={{marginRight:'5px'}}/>
                        Readings
                    </p>
                    <Accordion>
                        {props.readings.map((elemento, index) => (
                                <ReadingItem
                                    titulo={elemento.titulo}
                                    descripcion={elemento.descripcion}
                                    enlace={elemento.enlace}
                                    index={index}
                                    key={index}
                                />
                            ))
                        }
                    </Accordion>
                </div>
                <div id="videosContainer">
                    <p>
                        <FontAwesomeIcon icon={["fas", "film"]} size="1x" style={{marginRight:'5px'}}/> 
                        Videos
                    </p>
                    <Accordion>
                        {props.videos.map((elemento, index) => (
                                <VideoItem
                                    titulo={elemento.titulo}
                                    enlace={elemento.enlace}
                                    index={index+10}
                                    key={index+10}

                                />
                            ))
                        }
                    </Accordion>
                </div>
                <div id="activitiesContainer">
                    <p>
                        <FontAwesomeIcon icon={["fas", "calendar-check"]} size="1x" style={{marginRight:'5px'}}/> 
                        Activities
                    </p>
                    <Accordion>
                        {props.activities.map((elemento, index) => (
                                <ActivityItem
                                    titulo={elemento.titulo}
                                    descripcion={elemento.descripcion}
                                    enlace={elemento.enlace}
                                    index={index+100}
                                    key={index+100}
                                    deadline={elemento.deadline}
                                    presentation={elemento.presentation}
                                />
                            ))
                        }
                    </Accordion>
                </div>
            </div>
        )
}

export default CurrentActivities