import React, { Component } from "react";
import { Helmet } from "react-helmet";
import CourseHeader from "../../components/CourseHeader/CourseHeader";
import Gallery from "../../components/Gallery/Gallery";
import SlideGallery from "../../components/SlideGallery/SlideGallery";
import CurrentActivities from "../../components/CurrentActivities/CurrentActivities";
import CurrentActivities2 from "../../components/CurrentActivities2/CurrentActivities2";
import "./potencia.css";
import BannerImg from "../../assets/images/electricalpower/electricitytransmission.jpg";
import MyMethods from "../constants.js"
const BannerAuthor="   Joshua Leal   "
//======setup de la materia==============//
const InformacionCurso={
  nombre:"Potencia Eléctrica",
  ano:"2020-1"
}
const baseUrl= "http://www.oscarrosete.com/api/"
// const baseUrl= "http://www.localhost:1337/"
const activitiesUrl="activities-power-2020-s"
const readingsUrl="readings-power-2020-s"
const videosUrl="videos-power-2020-s"
const handsoutsUrl="handouts-power-2020-s"
const imagesUrl="gallery-power-2020-s"
const slidesUrl="slides-power-2020-s"
//======setup de la materia==============//
const updateActivities = MyMethods.updateActivities;
const updateSlides = MyMethods.updateSlides;
const updateImages = MyMethods.updateImages;
const updateReadings = MyMethods.updateReadings;
const updateVideos = MyMethods.updateVideos;
const updateHandouts = MyMethods.updateHandouts;

// {
//   titulo:'Cuarto Video (Sistema por Unidad)',
//   enlace:'https://www.youtube.com/embed/3CIAl84z-Ac'
// },

// {
//   titulo:'Segunda Actividad',
//   descripcion:'Leer Páginas 80 a 89',
//   deadline:'27/03/20',
//   presentation:'https://slides.com/oscarrosete/tarea-semana1/embed',
//   enlace:'https://ebiblio.cetys.mx:4153/lib/cetyssp/reader.action?docID=4849766&ppg=83'
// }

export class HomePage extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      activities:[],
      readings:[],
      videos:[],
      handouts:[],
      images:[],
      slides:[]
    };
  }

  async componentDidMount() {
    console.log("pidiendo")
    let arr= await updateActivities(baseUrl,activitiesUrl);
    let arr2= await updateReadings(baseUrl,readingsUrl);
    let arr3= await updateVideos(baseUrl,videosUrl);
    let arr4=await updateHandouts(baseUrl,handsoutsUrl);
    let arr5=await updateImages(baseUrl,imagesUrl);
    let arr6=await updateSlides(baseUrl,slidesUrl);

    this.setState({
      activities:arr,
      readings:arr2,
      videos:arr3,
      handouts:arr4,
      images:arr5,
      slides:arr6
    })
  }

  render() {
    return (
      <div className="home">
         
         <Helmet>
          <title>Curso {InformacionCurso.nombre}</title>
          <meta
            name="description"
            content={`Curso de ${InformacionCurso.nombre} impartido en Cetys universidad por Oscar Alonso Rosete Beas`}
          />
        </Helmet>
    
        <CourseHeader
          title={`${InformacionCurso.nombre} ${InformacionCurso.ano}`}
          img={BannerImg}
          img_author="Denys Nevozhai"
          phrase='"Scientists discover the world that exists; Engineers create the world that never was"'
          author="-Theodore Von Karman"
        />

        <div className='Divider1a'>
            <div className='Divider1b'>
              <p>Semana Actual (23-29 Marzo)</p>
            </div>
        </div>
        
        <CurrentActivities
          readings={this.state.readings}
          videos={this.state.videos}
          activities={this.state.activities}
        />

        <CurrentActivities2
          activities={this.state.handouts}
          url= "https://calendar.google.com/calendar/b/5/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FTijuana&amp;src=bmFpYjZoODlzdWM2aWNhN2xlczc5cDh0ZmNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23A79B8E&amp;mode=MONTH&amp;showPrint=0&amp;showTabs=0&amp;showNav=0&amp;showDate=0&amp;showTz=0&amp;hl=en&amp;showCalendars=0&amp;showTitle=0"
        />
         

        <div className='Divider1a'>
            <div className='Divider1b'>
              <p>Material Pasado</p>
            </div>
        </div>

        <SlideGallery
          slideArray={this.state.slides}
        />

        <div className='Divider1a'>
          <div className='Divider1b'>
            <p>Galeria de Fotos</p>
          </div>
        </div>

        <Gallery
            imgList={this.state.images}
        />
        
      </div>
    );
  }
}
export default HomePage;
