import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import "./ReadingItem.css";

const ListItem = (props) => {
  return (
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey={props.index} className="activityLink">
                        {props.titulo}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={props.index}>
                    <Card.Body className="activityLinkDescription">
                        <p>
                            Description: {props.descripcion}
                        </p>
                        <a href={props.enlace}>click me</a>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            )
        }

export default ListItem