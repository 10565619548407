import React, { Component } from "react";
import { Helmet } from "react-helmet";
import CourseHeader from "../../components/CourseHeader/CourseHeader";
import Gallery from "../../components/Gallery/Gallery";
import SlideGallery from "../../components/SlideGallery/SlideGallery";
import CurrentActivities from "../../components/CurrentActivities/CurrentActivities";
import CurrentActivities2 from "../../components/CurrentActivities2/CurrentActivities2";
import "./micro.css";
import BannerImg from "../../assets/images/micro/raspberry.jpg";
import MyMethods from "../constants.js"
const BannerAuthor="   Harrison Broadbent  "
const jsonArray=
    [
      {
        Texto:"Semana 1-1",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vSMfv3xEUqLcUhk0NBdYkZ6Fld2AOuZZKBr7kMnPO6agRiY62iDix11j0pWOIZkePRrO6ml9gVCiip1/embed?start=false&loop=false&delayms=3000"
      },
      {
      Texto:"Semana 1-2",
      slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vQeqJuwvk0M6jQDZZrAkaq1YlT1Kceq7qzPJ5SVMdZB6k_lqa4qDWxTSjtgHjMemg17Qgznb4-YZ6ZR/embed?start=false&loop=false&delayms=3000"
      },
      {
        Texto:"Semana 1-3",
        slidesUrl:"https://slides.com/oscaralonso11/estatica-s1-3/embed" 
      },
      {
        Texto:"Semana 2-1",
        slidesUrl:"https://slides.com/oscaralonso11/estatica-s1-3-bb3521/embed"
      },
      {
        Texto:"Semana 2-2",
        slidesUrl:"https://slides.com/oscaralonso11/microcontroladores-s1-3/embed"
      },
      {
        Texto:"Semana 2-3",
        slidesUrl:"https://slides.com/oscaralonso11/microcontroladores-s2-1/embed"
      },
      {
        Texto:"Ensamblador-1",
        slidesUrl:"https://slides.com/oscaralonso11/ens-1/embed"
      },
      {
        Texto:"Ensamblador-2",
        slidesUrl:"https://slides.com/oscaralonso11/ens-2/embed"
      },
      {
        Texto:"Ensamblador-3",
        slidesUrl:"https://slides.com/oscaralonso11/ens-3/embed"
      },
      {
        Texto:"Ensamblador-4",
        slidesUrl:"https://slides.com/oscaralonso11/ens-4/embed"
      },
      {
        Texto:"Ensamblador-simulacion",
        slidesUrl:"https://slides.com/oscaralonso11/ens-simulation/embed"
      },
      {
        Texto:"Ensamblador-diagrama",
        slidesUrl:"https://slides.com/oscaralonso11/ens-flowchart/embed"
      },
      {
        Texto:"Ensamblador-switch",
        slidesUrl:"https://slides.com/oscaralonso11/ens-switch/embed"
      },
      {
        Texto:"Ensamblador-retos",
        slidesUrl:"https://slides.com/oscaralonso11/pic-challenges/embed"
      },
      {
        Texto:"c-1",
        slidesUrl:"https://slides.com/oscaralonso11/pic-c1/embed"
      },
      {
        Texto:"c-2",
        slidesUrl:"https://slides.com/oscaralonso11/pic-c2/embed"
      }
]
// {
//   titulo:'Segunda Lectura (Beer&Johnston)',
//   descripcion:'Leer Páginas 89 a 86, Mecánica vectorial para ingenieros(Beer&Johnston)',
//   enlace:'https://drive.google.com/file/d/1INPAmqr32ttWIcfoUkpQB5odDtrtcDzo/view?usp=sharing'
// },
//======setup de la materia==============//
const InformacionCurso={
  nombre:"Microcontroladores",
  ano:"2020-1"
}
// const baseUrl= "http://www.oscarrosete.com/api/"
const baseUrl= "http://www.localhost:1337/"
const activitiesUrl="activities-micro-2020-s"
const readingsUrl="readings-micro-2020-s"
const videosUrl="videos-micro-2020-s"
const handsoutsUrl="handouts-micro-2020-s"
const imagesUrl="gallery-micro-2020-s"
const slidesUrl="slides-micro-2020-s"
//======setup de la materia==============//
const updateActivities = MyMethods.updateActivities;
const updateSlides = MyMethods.updateSlides;
const updateImages = MyMethods.updateImages;
const updateReadings = MyMethods.updateReadings;
const updateVideos = MyMethods.updateVideos;
const updateHandouts = MyMethods.updateHandouts;

export class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      activities:[],
      readings:[],
      videos:[],
      handouts:[],
      images:[],
      slides:[]
    };
  }

  async componentDidMount() {
    console.log("pidiendo")
    let arr= await updateActivities(baseUrl,activitiesUrl);
    let arr2= await updateReadings(baseUrl,readingsUrl);
    let arr3= await updateVideos(baseUrl,videosUrl);
    let arr4=await updateHandouts(baseUrl,handsoutsUrl);
    let arr5=await updateImages(baseUrl,imagesUrl);
    let arr6=await updateSlides(baseUrl,slidesUrl);

    this.setState({
      activities:arr,
      readings:arr2,
      videos:arr3,
      handouts:arr4,
      images:arr5,
      slides:arr6
    })
  }

  render() {
    return (
      <div className="home">
         
         <Helmet>
          <title>Curso {InformacionCurso.nombre}</title>
          <meta
            name="description"
            content={`Curso de ${InformacionCurso.nombre} impartido en Cetys universidad por Oscar Alonso Rosete Beas`}
          />
        </Helmet>
    
        <CourseHeader
          title={`${InformacionCurso.nombre} ${InformacionCurso.ano}`}
          img={BannerImg}
          img_author="Denys Nevozhai"
          phrase='"Scientists discover the world that exists; Engineers create the world that never was"'
          author="-Theodore Von Karman"
        />

        <div className='Divider1a'>
            <div className='Divider1b'>
              <p>Semana Actual (23-29 Marzo)</p>
            </div>
        </div>
        
        <CurrentActivities
          readings={this.state.readings}
          videos={this.state.videos}
          activities={this.state.activities}
        />

        <CurrentActivities2
          activities={this.state.handouts}
          url= "https://calendar.google.com/calendar/b/5/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FTijuana&amp;src=a2tzZjh1dDdsYW9ua2Jnc2JkNWFwMHU4czBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23A79B8E&amp;mode=MONTH&amp;showPrint=0&amp;showTabs=0&amp;showNav=0&amp;showDate=0&amp;showTz=0&amp;hl=en&amp;showCalendars=0&amp;showTitle=0"
        />
         

        <div className='Divider1a'>
            <div className='Divider1b'>
              <p>Material Pasado</p>
            </div>
        </div>

        <SlideGallery
          slideArray={jsonArray}
        />

        <div className='Divider1a'>
          <div className='Divider1b'>
            <p>Galeria de Fotos</p>
          </div>
        </div>

        <Gallery
            imgList={this.state.images}
        />
        
      </div>
    );
  }
}
export default HomePage;
