import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Calendar from "../../components/Calendar/Calendar";
import Accordion from 'react-bootstrap/Accordion'
import PresentationItem from "./PresentationItem/PresentationItem";

import "./CurrentActivities2.css";

const CurrentActivities2 = (props) => {
  return (
            <div id="seccion2">
                <div id="presentaciones">
                    <p id="title">
                        <FontAwesomeIcon icon={["fas", "film"]} size="1x" style={{marginRight:'5px'}}/> 
                        Handouts / Slides
                    </p>
                    <Accordion>
                        {props.activities.map((elemento, index) => 
                        {
                            return(
                                <PresentationItem
                                    titulo={elemento.titulo}
                                    descripcion={elemento.descripcion}
                                    enlace={elemento.enlace}
                                    index={(index+1000)}
                                    key={(index+1000)}
                                    presentation={elemento.presentation}
                                />
                            )
                        }
                            )
                            
                        }
                    </Accordion>
                </div>
                
                <div id="calendar">
                    <p id="calendarTitle">
                        <FontAwesomeIcon icon={["fas", "calendar-check"]} size="1x" style={{marginRight:'5px'}}/> 
                        Calendar
                    </p>
                    <Calendar
                        url={props.url}
                    />
                </div>
            </div>    
        )
}

export default CurrentActivities2