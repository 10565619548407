import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import "./PresentationItem.css";

const PresentationItem = (props) => {
      return (
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey={props.index} className="activityLink">
                        {props.titulo}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={props.index}>
                    <Card.Body className="activityLinkDescription">
                        <p style={{textAlign:'justify'}}>
                            Description: {props.descripcion}
                        </p>
                        {typeof(props.enlace)!='undefined'?  
                            <a href={props.enlace}>click me</a> :
                            <span></span>
                        }
                        {typeof(props.presentation)!='undefined'?  
                            <iframe src={props.presentation}
                            width="100%" height="80%" frameBorder="2px"  
                            allowFullScreen mozallowfullscreen="true" 
                            webkitallowfullscreen="true">
                            </iframe> :
                            <span></span>
                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            )
        }

export default PresentationItem