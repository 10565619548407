//   export default function getNumber(value) {
//     return parseFloat(value) || 0;
//   }
let MyMethods={
    updateSlides: async function(baseUrl,slidesUrl){
      console.log(`fetching ${baseUrl}${slidesUrl}`)
      let arr=await fetch(`${baseUrl}${slidesUrl}`,)
      .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            arr=result.map((el)=>
            {
                var info = { Texto:el.Title,
                slidesUrl:el.Link,
                descripcion: el.Description,
                presentation: el.Presentation
                }
                return info;
            })
            return arr;
          }
        )
        console.log("slidespasados generados ")
        console.log(arr)
        return arr;
    },
    
    updateImages:  async function(baseUrl,imagesUrl){
      console.log(`fetching ${baseUrl}${imagesUrl}`)
        let arr=await fetch(`${baseUrl}${imagesUrl}`,)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            arr=result.map((el)=>
            {
                var info = { description:el.Title,
                url:el.Link,
                }
                return info;
            })
            return arr;
          }
        )
        console.log("imagenes generados ")
        console.log(arr)
        return arr;
    },
      
    updateActivities:  async function(baseUrl,activitiesUrl){
      console.log(`fetching ${baseUrl}${activitiesUrl}`)
      let arr=await fetch(`${baseUrl}${activitiesUrl}`)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            arr=result.map((el)=>
            {
                var info = { titulo:el.Title,
                descripcion:el.Description,
                deadline:el.Deadline,
                presentation:el.Presentation
                }
                return info;
            })
            return arr;
          }
        )
        console.log("Actividades generado ")
        console.log(arr)
        return arr;
    },
      
    updateReadings: async function(baseUrl,readingsUrl){
        console.log(`fetching ${baseUrl}${readingsUrl}`)
        let arr=await fetch(`${baseUrl}${readingsUrl}`)
        .then(res => res.json())
        .then((result) => {
            console.log(result)
            arr=result.map((el)=>
            {
                var info = { titulo:el.Title,
                descripcion:el.Description,
                enlace:el.Link,
                }
                return info;
            })
            return arr;
          }
        )
        console.log("Lecturas generado ")
        console.log(arr)
        return arr;
    },
      
    updateVideos: async function(baseUrl,videosUrl){
      console.log(`fetching ${baseUrl}${videosUrl}`)
      let arr=await fetch(`${baseUrl}${videosUrl}`)
      .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            arr=result.map((el)=>
            {
                var info = { titulo:el.Title,
                enlace:el.Link,
                }
                return info;
            })
            return arr;
          }
        )
        console.log("videos generados ")
        console.log(arr)
        return arr;
    },
      
    updateHandouts: async function(baseUrl,handsoutsUrl){
      console.log(`fetching ${baseUrl}${handsoutsUrl}`)
      let arr=await fetch(`${baseUrl}${handsoutsUrl}`)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            arr=result.map((el)=>
            {
                var info = { titulo:el.Title,
                enlace:el.Link,
                descripcion: el.Description,
                presentation: el.Presentation
                }
                return info;
            })
            return arr;
          }
        )
        console.log("handouts generados ")
        console.log(arr)
        return arr;
      }
}

export default MyMethods
// module.exports=updateActivities;