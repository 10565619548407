import React, { Component } from "react";
// import { Container, Row, Col } from "react-bootstrap";

// import Intro from "../components/Home/Intro/Intro";
// import About from "../components/Home/About/About";
// import Services from "../components/Home/Services/Services";
// import Networks from "../components/Home/Networks/Networks";
// import Contact from "../containers/Contact/Contact";

// ponermetatags conhelmet
import { Helmet } from "react-helmet";
import Banner from "../../components/Banner/Banner";
import "./neumatica.css";
import BannerImg from "../../assets/images/pneumatics/pneuma.jpg";

export class neumaticaPage extends Component {


  render() {
    const jsonArray=
    [
      {
        Texto:"Semana 1-1",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vREC_qTvDV-im-Xy77u8c7i6Rk6OBS9TSSHPOly57hZovV0sHroMcIzwQGQpcpk4lNs4-HBW_J8anfC/embed?start=false&loop=false&delayms=3000"
      },
      {
      Texto:"Semana 1-2",
      slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vT6u2z1EZtSUZYEP_QksQDoXL4sTdbUaAmP_GExrkreU6qF-p8wUv_X-ZiOCBMHyEq414LlfafGM1f7/embed?start=false&loop=false&delayms=3000"
      },
      {
        Texto:"Semana 2-1",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vRIydJJzOoTL_OJ0cRC0hIn6ts0eLzlRghTI8Q6NgdkVs50KzP-csDZ6N8vUrUrHOjJoTe2TnkkxFdA/embed?start=false&loop=false&delayms=3000"
      },
      {
        Texto:"Semana 2-2",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vTsYnvO5bbWHJ50O-Lz2ra363Jgo4Kf0C5JqCXsY8RzsQ3-HfeeRUzFFSloc4JCY0HvgwGnJDuqxaj0/embed?start=false&loop=false&delayms=3000"
      },
      //
      {
        Texto:"Semana 3-1",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vSNWGrHu5srIwxUq5MYLglAu81ULFWQPTkcJjSzUObjJAIad8fItuNJwmcHRSnltf41eHG8DkWjRuDO/embed?start=false&loop=false&delayms=3000"
      },
      {
        Texto:"Semana 3-2",
        slidesUrl:"https://docs.google.com/presentation/d/e/2PACX-1vTJXbj3cvwAgEVjXO2soP72Qmq3s05vL073fYEBVPDFKTri4pIGfC_kQmk9Txav9oSmZcyVRsk-EqLo/embed?start=false&loop=false&delayms=3000"
      },
      //
      {
        Texto:"Semana 4-1",
        slidesUrl:`
        https://docs.google.com/presentation/d/e/2PACX-1vTKhDEMO-VuZokp4vQuxsmyGPGCp3rEbzp4KB165mN6vuP6ZzzuLKmuBEI-jocevNxDEszCLrUP6vET/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 4-2",
        slidesUrl:`
        https://docs.google.com/presentation/d/e/2PACX-1vQwbyH2oaDZA9o18HfDgvPFTijbrlEaXWXIdFCKwq1MP0WwEGd5ptguhbbeNLj6Vboxvx1-bVpKWpzd/embed?start=false&loop=false&delayms=3000        `
      },
      //
      {
        Texto:"Semana 5-1",
        slidesUrl:`
        https://docs.google.com/presentation/d/e/2PACX-1vQYUtjQqKdF9g9M7iCcqSYHcGFr0E9R1jbZDuVvDzKzYJw0ZVP61G_ZiMtJbkD206vydQA3TpiBbLI9/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 5-2",
        slidesUrl:`
        https://docs.google.com/presentation/d/e/2PACX-1vRRsz7I1er9IGAwI50fMJEGYw94JMY8R8mIIy2TEwHgD6UPZwlFMqzIrWw-ctQ1Yp93fDrNuMTGOM3h/embed?start=false&loop=false&delayms=3000
        `
      },
      //
      {
        Texto:"Semana 6",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vS0MOS3-SDuiLN5zF0VUgX5mOQJvp-1EpnCyNc4F2z5kXivucx41bQlgKAOPPhYmoY13GHg8_7H526W/embed?start=false&loop=false&delayms=3000
        `
      },
      //
      {
        Texto:"Semana 7-1",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vSVPOgi66Dv4ySnfDmHAY70gv9Guc3YlsLfMYfGLMVdNoH57qq9Ake3eXq-AzFyrmRze3KZ9uD9xa5C/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 7-2",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vTXfaKDb_PRVEJG8ZBpEPrRkEUdyXvV7b4qrZ05sxevy_xOLHf_xyo04WcEeQ9927uYBhkRQ3GwyEqr/embed?start=false&loop=false&delayms=3000       
         `
      },
      {
        Texto:"Semana 8-1",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vRsO8NjaFBPtXBXKeiCWIIhJPlR8qyyuwhy2XS2V0qOYARljKdHp39e3g5U7kRIJ-IBJgEMx5yOLv-O/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 8-2",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vQ6RFmxtxFDzaYpJxbROK9fgHxhd2Uprstwoz7bYGZaBvhMH-o85QKcMdra3T1ksajpXQcsx3DVpoKv/embed?start=false&loop=false&delayms=3000        
        `
      },
      {
        Texto:"Semana 9-1",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vQcorao4u7OFZiMrxDD0ZxRHOxxcypqC4qK5aqqn32vPUxdiCcf22j3dWSgSr6VcjKe1QQ-1fkjYvtk/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 9-2",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vTJe85ioMUBLDI-uEEsa6R2RhvZD1OtEL94xtRQYUXLmd74xLogi4gp8YQSr5ShHeCd0_a7HH6GCsoT/embed?start=false&loop=false&delayms=3000       
        `
      },
      {
        Texto:"Semana 10-1",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vRNJnkeWQB97Eysmbb16P4BqlOUk4jz8wkti1xiEuwHLRXB-fZ6hKMJ8OEgAAlsrlfswZU9C62HABm1/embed?start=false&loop=false&delayms=3000        
        `
      },
      {
        Texto:"Semana 10-2",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vT8VCWggA-rkniFciXXOtkf_-b82DqvE_7RFefA8tmG-C6DnpDQTd_I9SwzD2FWmrSEYTwrflV79jxI/embed?start=false&loop=false&delayms=3000      
        `
      },
      {
        Texto:"Semana 13-1",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vSwjJkyRn8_6jiYp_c8qVtKmm3Dqj3zTJq07QdYESfC6KRTjgSOs6YAWqXNUeAn2rFgFUaXELXIU1F2/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 13-2",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vQo2zF-Yjzt_5Rmmyc0U-az9IHi-pmC_dwBvWfCph0pNK8P3TJBp4t0ZkVpf55hyFW1_Ht9u5ICP0QF/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 14-1",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vQM5G_MuYy3arkAVCrEL5LYHjzqYeOX2ydYzMQhr7QpTkEh-Rc_drq3EyWo-AaspqlZue94PzmsKS3W/embed?start=false&loop=false&delayms=3000
        `
      },
      {
        Texto:"Semana 14-2",
        slidesUrl:
        `
        https://docs.google.com/presentation/d/e/2PACX-1vS7cpGG3JEWvbzthX7VMTqGA-OUMGu3XsoYbxtt-9PHthRhjbPQDj1u5nu8Bu0cwOVnoIGJJpjbAEqs/embed?start=false&loop=false&delayms=3000
        `
      },
    ]

    return (
      <div className="home">
        <Helmet>
          <title>Curso Sistemas Hidráulicos y Neumáticos</title>
          <meta
            name="description"
            content="Curso de Sistemas Hidráulicos y Neumáticos impartido en Cetys universidad por Oscar Alonso Rosete Beas"
          />
        </Helmet>
        <Banner
          img={BannerImg}
          phrase='"Scientists discover the world that exists; Engineers create the world that never was"'
          author="-Theodore Von Karman"
        />
        <h1 className="page__title">Bienvenido a nuestro curso!</h1>
        <div id="miflex">
            {jsonArray.map((elemento, index) => (
              <div className="col-6" >
              {elemento.Texto}
              <iframe src={elemento.slidesUrl}
              width="100%" height="80%" frameborder="0"  allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default neumaticaPage;