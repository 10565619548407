// import React, { Component } from "react";
import React, { Fragment, Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavLink } from "react-router-dom";

// ponermetatags conhelmet
import { Helmet } from "react-helmet";
import "./index.css";
// import "./superslides.css";
// import useScript from './hooks/useScript';
import Banner1 from "../../assets/images/caroussel/slide1.jpg";
import Banner2 from "../../assets/images/caroussel/slide2.jpg";
import Typist from "react-typist";
// import Swiper from 'swiper';
// import {SwiperSlide} from 'swiper';
import Caroussel from "./caroussel/caroussel";
// import PhotoGrid from './photogrid/photogrid';
// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import tdImage from "../../assets/images/technical/technical_classroom.jpg";
import mImage from "../../assets/images/micro/micro_classroom.jpg";
import sImage from "../../assets/images/statics/statics_classroom2.jpg";
import sprevImage from "../../assets/images/statics/statics_prevclassroom.jpg";
import myImage from "../../assets/images/landing/imagen.png";
import cetysImage from "../../assets/images/landing/cetys.png";
import salvaImage from "../../assets/images/landing/SALVA.png";

// const blackPixel=`https://via.placeholder.com/800/0000FF/808080 ?Text=Digital.com`
// const staticsPrevImage="https://via.placeholder.com/800/212121/FFFFFF/?text=Estatica 2019";
// const staticsImage='https://via.placeholder.com/800/0000FF/FFFFFF/?text=Estatica 2020'
// const microcontrollersImage=`https://via.placeholder.com/800/212121/FFFFFF/?text=Micro 2020`
// const technicalImage='https://via.placeholder.com/800/0000FF/FFFFFF/?text=Technical Drawing'

const technicalImage = tdImage;
const microcontrollersImage = mImage;
const staticsImage = sImage;
const staticsPrevImage = sprevImage;

const powerImage =
  "https://via.placeholder.com/4096x3072/0000FF/FFFFFF/?text=Potencia Eléctrica";
var classNames = require("classnames");
var colClasses = classNames("mb-5", "colClass");

export class HomePage extends Component {
  render() {
    const classes_2021_1 = [
      {
        id: 0,
        title: "Microcontroladores",
        period: "2021-1",
        img: "https://live.staticflickr.com/65535/50826771047_6fc11dfec9_c.jpg",
        path: "/microcontrollers-2021",
      },
      {
        id: 1,
        title: "Sistemas Inteligentes",
        period: "2021-1",
        img: "https://live.staticflickr.com/65535/50826202493_a81c1c847d_c.jpg",
        path: "/intelligent-systems-2021",
      },
      {
        id: 2,
        title: "Dinámica de Mecanismos",
        period: "2021-1",
        img: "https://live.staticflickr.com/65535/50826252703_6e16fa9d7c_c.jpg",
        path: "/mechanisms-2021",
      },
      {
        id: 3,
        title: "Potencia Eléctrica",
        period: "2021-1",
        img: "https://live.staticflickr.com/65535/50827360141_d87ed5ae12_c.jpg",
        path: "/electrical-power-systems-2021",
      },
    ];

    const classes_2021_2 = [
      {
        id: 0,
        title: "Circuitos Eléctricos II",
        period: "2021-2",
        img: "https://live.staticflickr.com/65535/50205318032_eb08c3f6d1_o.jpg",
        path: "/electrical-circuits-2021",
      },
      {
        id: 1,
        title: "Conversión de Energía",
        period: "2021-2",
        img: "https://live.staticflickr.com/65535/50204494008_c08c4054dc_o.jpg",
        path: "energy-conversion-2021",
      },
      {
        id: 2,
        title: "Sistemas Hidráulicos",
        period: "2021-2",
        img: "https://live.staticflickr.com/65535/50205049926_b5a04605d1_o.jpg",
        path: "/electropneumatics-2021",
      },
    ];

    const classes_2022_1 = [
      {
        id: 0,
        title: "Microcontroladores",
        period: "2022-1",
        img: "https://live.staticflickr.com/65535/50826771047_6fc11dfec9_c.jpg",
        path: "/microcontrollers-2022",
      },
      {
        id: 1,
        title: "Sistemas Inteligentes",
        period: "2022-1",
        img: "https://live.staticflickr.com/65535/50826202493_a81c1c847d_c.jpg",
        path: "/intelligent-systems-2022",
      },
      {
        id: 2,
        title: "Dinámica de Mecanismos",
        period: "2022-1",
        img: "https://live.staticflickr.com/65535/50826252703_6e16fa9d7c_c.jpg",
        path: "/mechanisms-2022",
      },
      {
        id: 3,
        title: "Redes de Computadora",
        period: "2022-1",
        img: "https://live.staticflickr.com/65535/51777189241_31c832a922_c.jpg",
        path: "/computer-networks-2022",
      },
    ];

    const classes_2022_2 = [
      {
        id: 0,
        title: "Circuitos Eléctricos II",
        period: "2022-2",
        img: "https://live.staticflickr.com/65535/50205318032_eb08c3f6d1_o.jpg",
        path: "/electrical-circuits-2022",
      },
      {
        id: 2,
        title: "Sistemas Hidráulicos",
        period: "2022-2",
        img: "https://live.staticflickr.com/65535/50205049926_b5a04605d1_o.jpg",
        path: "/electropneumatics-2022",
      },
    ];

    const classes_2023_1 = [
      {
        id: 0,
        title: "Microcontroladores",
        period: "2023-1",
        img: "https://live.staticflickr.com/65535/50826771047_6fc11dfec9_c.jpg",
        path: "/microcontrollers-2023",
      },
      {
        id: 2,
        title: "Dinámica de Mecanismos",
        period: "2023-1",
        img: "https://live.staticflickr.com/65535/50826252703_6e16fa9d7c_c.jpg",
        path: "/mechanisms-2023",
      },
      {
        id: 3,
        title: "Redes de Computadora",
        period: "2023-1",
        img: "https://live.staticflickr.com/65535/51777189241_31c832a922_c.jpg",
        path: "/computer-networks-2023",
      },
    ];

    return (
      <div className="index">
        <Helmet>
          <title>Engineer Oscar Rosete's Website</title>
          <meta
            name="description"
            content="Sitio web enfocado a respaldar material de cursos de Oscar Alonso Rosete Beas"
          />
        </Helmet>
        <Row className="justify-content-md-center">
          <Col xs="12" lg="6" id="lanMesCont" style={{ padding: 0 }}>
            <div id="landingMessage">
              <div>
                Full-time Professor, Software Developer and Automation
                Specialist
              </div>
            </div>
            <div id="circle" />
          </Col>
          <Col id="carousselContainer" xs="12" lg="6" style={{ padding: 0 }}>
            <Caroussel />
          </Col>
        </Row>
        {/* <h1 className="index__title">Site under Construction</h1> */}
        <div className="cursosContainer1a">
          <div className="cursosContainer1b">
            <p>Actual Courses 2023-1</p>
          </div>
        </div>

        <Container>
          <Row className="justify-content-md-center">
            {classes_2023_1.map((course) => (
              <Col xs="12" lg="4" className="colClass" key={course.id}>
                <NavLink to={course.path}>
                  <Image src={course.img} rounded fluid />
                </NavLink>
                <div className="cuadrito">
                  <p>{course.title}</p>
                  <p> {course.period}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>

        <div className="cursosContainer1a">
          <div className="cursosContainer1b">
            <p>2022-2 Courses</p>
          </div>
        </div>

        <Container>
          <Row className="justify-content-md-center">
            {classes_2022_2.map((course) => (
              <Col xs="12" lg="4" className="colClass" key={course.id}>
                <NavLink to={course.path}>
                  <Image src={course.img} rounded fluid />
                </NavLink>
                <div className="cuadrito">
                  <p>{course.title}</p>
                  <p> {course.period}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>

        <div className="cursosContainer1a">
          <div className="cursosContainer1b">
            <p>2022-1 Courses</p>
          </div>
        </div>
        <Container>
          <Row className="justify-content-md-center">
            {classes_2022_1.map((course) => (
              <Col xs="12" lg="4" className="colClass" key={course.id}>
                <NavLink to={course.path}>
                  <Image src={course.img} rounded fluid />
                </NavLink>
                <div className="cuadrito">
                  <p>{course.title}</p>
                  <p> {course.period}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>

        <div className="cursosContainer1a">
          <div className="cursosContainer1b">
            <p>2021-2 Courses</p>
          </div>
        </div>
        <Container>
          <Row className="justify-content-md-center">
            {classes_2021_2.map((course) => (
              <Col xs="12" lg="4" className="colClass" key={course.id}>
                <NavLink to={course.path}>
                  <Image src={course.img} rounded fluid />
                </NavLink>
                <div className="cuadrito">
                  <p>{course.title}</p>
                  <p> {course.period}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>

        <div className="cursosContainer1a">
          <div className="cursosContainer1b">
            <p>2021-1 Courses</p>
          </div>
        </div>

        <Container>
          <Row className="justify-content-md-center">
            {classes_2021_1.map((course) => (
              <Col xs="12" lg="4" className="colClass" key={course.id}>
                <NavLink to={course.path}>
                  <Image src={course.img} rounded fluid />
                </NavLink>
                <div className="cuadrito">
                  <p>{course.title}</p>
                  <p> {course.period}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>

        <div className="cursosContainer1a">
          <div className="cursosContainer1b">
            <p>2020-2 Courses</p>
          </div>
        </div>

        {/* <h1 className="index__title">Cursos 2020-1</h1> */}
        <Container>
          <Row className="justify-content-md-center">
            <Col xs="12" lg="4" className="colClass">
              <NavLink key="Home" to="/automation-2020">
                <Image
                  src="https://live.staticflickr.com/65535/50205314792_a50b3df6e0_o.jpg"
                  rounded
                  fluid
                />
              </NavLink>
              <div className="cuadrito">
                <p>Automatización</p>
                <p> 2020-2</p>
              </div>
            </Col>

            <Col xs="12" lg="4" className="colClass">
              <NavLink key="Home" to="/electrical-circuits-2020">
                <Image
                  src="https://live.staticflickr.com/65535/50205318032_eb08c3f6d1_o.jpg"
                  rounded
                  fluid
                />
              </NavLink>
              <div className="cuadrito">
                <p>Circuitos Eléctricos II</p>
                <p> 2020-2</p>
              </div>
            </Col>

            <Col xs="12" lg="4" className="colClass">
              <NavLink key="Home" to="/electropneumatics-2020">
                <Image
                  src="https://live.staticflickr.com/65535/50205049926_b5a04605d1_o.jpg"
                  rounded
                  fluid
                />
              </NavLink>
              <div className="cuadrito">
                <p>Sistemas Hidráulicos</p>
                <p> 2020-2</p>
              </div>
            </Col>

            <Col xs="12" lg="4" className="colClass">
              <NavLink key="Home" to="/multivariable-calculus-2020">
                <Image
                  src="https://live.staticflickr.com/65535/50205321037_299deff898_o.jpg"
                  rounded
                  fluid
                />
              </NavLink>
              <div className="cuadrito">
                <p>Cálculo Multivariable</p>
                <p> 2020-2</p>
              </div>
            </Col>

            <Col xs="12" lg="4" className="colClass">
              <NavLink key="Home" to="/energy-conversion-2020">
                <Image
                  src="https://live.staticflickr.com/65535/50204494008_c08c4054dc_o.jpg"
                  rounded
                  fluid
                />
              </NavLink>
              <div className="cuadrito">
                <p>Conversión de Energía</p>
                <p> 2020-2</p>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="cursosContainer1a">
          <div className="cursosContainer1b">
            <p>Previous Courses</p>
          </div>
        </div>

        <Container>
          <Row className="justify-content-md-center">
            <Col xs="12" lg="4" className="colClass">
              <NavLink key="Home" to="/estatica2020">
                <Image src={staticsImage} rounded fluid />
              </NavLink>
              <div className="cuadrito">
                <p>Estática</p>
                <p> 2020-1</p>
              </div>
              {/* <a href="/estatica">
                  <Image src={grayPixel} rounded fluid />
                </a> */}
              {/* 1 of 3 */}
            </Col>
            <Col xs="12" lg="4" className="colClass">
              <NavLink key="Home" to="/microcontroladores2020">
                <Image src={microcontrollersImage} rounded fluid />
              </NavLink>
              <div className="cuadrito">
                <p>Microcontroladores</p>
                <p> 2020-1</p>
              </div>
            </Col>
            <Col xs="12" lg="4" className="mb-5">
              <NavLink key="Home" to="/technical2020">
                <Image src={technicalImage} rounded fluid />
              </NavLink>
              <div className="cuadrito">
                <p>Technical Drawing</p>
                <p> 2020-1</p>
              </div>
            </Col>
            <Col xs="12" lg="4" className="mb-5">
              <NavLink key="Home" to="/potencia2020">
                <Image src={powerImage} rounded fluid />
              </NavLink>
              <div className="cuadrito">
                <p>Potencia Eléctrica</p>
                <p>2020-1</p>
              </div>
            </Col>
          </Row>
        </Container>

        <Row className="justify-content-md-center" id="social-networks">
          <Col xs="12" lg="8" id="myDescription">
            <img id="myImage" src={myImage} alt="myImage" />
            <div>
              <p id="name">Oscar Alonso Rosete Beas</p>
              <p id="nameDescription">
                Desarrollador de Software apasionado por la docencia
              </p>
            </div>
          </Col>
          <Col xs="12" lg="4" id="contactInfo">
            <div className="infoRow">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/oscar-rosete/"
              >
                <img className="iconsImages" src={cetysImage} alt="myImage" />
              </a>
              <span>CETYS Universidad</span>
            </div>

            {/* <div className="infoRow">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/oscar-rosete/"
              >
                <img className="iconsImages" src={salvaImage} alt="myImage" />
              </a>
              <span>Instituto Salvatierra</span>
            </div> */}

            <div className="infoRow">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/oscar-rosete/"
              >
                <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" />
              </a>
              <span style={{ marginLeft: "20px" }}>Professional Profile</span>
            </div>

            <div className="infoRow">
              <a href="mailto:oscar.rosete@cetys.mx" target="_top">
                <FontAwesomeIcon icon={["fas", "envelope"]} size="2x" />
              </a>
              <span style={{ marginLeft: "20px" }}>Contact me!</span>
            </div>
            <div className="social-networks">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/oscar.rosetebeas"
              >
                <FontAwesomeIcon icon={["fab", "facebook"]} size="2x" />
              </a>
            </div>
          </Col>
        </Row>

        {/* <PhotoGrid></PhotoGrid> */}
        {/* <p style={{ textAlign: "center", fontSize: "large" }}>
            Have any question or request?{" "}
            <a href="mailto:oscaralonso11@hotmail.com" target="_top">
              Contact me!
            </a>
          </p>
          <div style={{marginBottom:"2vh"}} className="LI-profile-badge"  data-version="v1" data-size="large" data-locale="es_ES" data-type="horizontal" data-theme="dark" data-vanity="oscar-alonso-rosete-beas-7a757889"><a className="LI-simple-link" href='https://mx.linkedin.com/in/oscar-alonso-rosete-beas-7a757889?trk=profile-badge'>Oscar Alonso Rosete Beas</a></div>  */}
        {/* <a
          href="https://www.patreon.com/bePatron?u=33538054"
          data-patreon-widget-type="become-patron-button"
        >
          Become a Patron!
        </a>
        <script
          async
          src="https://c6.patreon.com/becomePatronButton.bundle.js"
        /> */}
      </div>
    );
  }
}

export default HomePage;
