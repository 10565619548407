import React from 'react'
import "./CourseHeader.css";

const CourseHeader = (props) => {
  return (
      <div id="headerContainer">
          {/* <img src={props.img} className="blog__banner-img" alt="blog-banner"></img> */}
          <div id="header_title-container">
            <div id="header-title">
              {props.title}
            </div>
            <p id="header-phrase">{props.phrase} </p>
            <p id="header-phrase-author">{props.author} </p>

          </div>
          <div id="header_image-container">
            <img src={props.img} id="header__course-img" alt="blog-banner">
            </img>
            <p id="img_author">Photo by: {props.img_author}</p>
          </div>
          {/* <p className="blog__banner-author">{props.author}</p>
          <div className="blog__banner-cover" /> */}
          {/* <p>hola</p> */}
      </div>
  )
}

export default CourseHeader